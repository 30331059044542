<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("my.school") }}
          </template>
          <template v-slot:toolbar>
            
          </template>
          <template v-slot:body>
            <table>
              <tr><td>Название</td><td>{{mySchool.nameRu}}</td></tr>
              <tr><td>Адрес</td><td>{{mySchool.address}}</td></tr>
              <tr><td>Контактный номер</td><td>{{mySchool.contactPhone}}</td></tr>
              <tr><td>Email</td><td>{{mySchool.email}}</td></tr>
              <tr><td>Сайт школы</td><td>{{mySchool.webSite}}</td></tr>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
td>button{
  margin-right: 10px;
}
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "mySchool",
  components: {
    KTPortlet
  },
  data() {
    return {
      mySchool: null,
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      ApiService.querySecured("my/school")
          .then(({ data }) => {
            this.mySchool = data;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    }
  }
};
</script>
